import React, { FunctionComponent } from "react";
import MaybeClassProps from "../../../components/MaybeClassProps.type";
import Strong from "../../../components/Strong";
import Table, {
  TableRow,
  TableCell,
  TableHeader,
} from "../../../components/Table/Table";
import { mergeClassNames } from "../../../helpers";

const UltrasoundSurveilanceTablesMobile: FunctionComponent<MaybeClassProps> = ({
  className,
}) => {
  return (
    <>
      <Strong className="text-black-dark mb-2 block">
        At-risk populations
      </Strong>
      <Table grid>
        <TableRow header>
          <TableHeader colSpan={2}>
            AASLD<sup>1</sup>
          </TableHeader>
        </TableRow>
        <TableRow>
          <TableCell>
            <ol className="list-decimal ml-4 text-sm p-1">
              <li>All HBsAg+ persons with cirrhosis</li>
              <li>People with a family history of HCC</li>
              <li>People with hepatitis D virus infection</li>
              <li>HBsAg+ Asian or African American men >40 years of age</li>
              <li>HBsAg+ Asian women >50 years of age</li>
            </ol>
          </TableCell>
        </TableRow>
      </Table>
      <Table grid>
        <TableRow header>
          <TableHeader colSpan={2}>
            AATA<sup>5</sup>
          </TableHeader>
        </TableRow>
        <TableRow>
          <TableCell>
            (Asian Americans)<sup>d</sup>
            <ol className="list-decimal ml-4 text-sm p-1">
              <li>All HBsAg+ people with chronic hepatitis</li>
              <li>People with cirrhosis or family history of HCC</li>
            </ol>
          </TableCell>
        </TableRow>
      </Table>

      <Strong className="text-black-dark mt-12 mb-2 block">
        Recommended HCC surveillance
      </Strong>
      <Table grid>
        <TableRow header>
          <TableHeader colSpan={2}>
            AASLD<sup>1</sup>
          </TableHeader>
        </TableRow>
        <TableRow>
          <TableCell>
            <ul className="list-disc ml-4 text-sm p-1">
              <li>Ultrasound ± AFP</li>
              <li>Every 6 months</li>
            </ul>
          </TableCell>
        </TableRow>
      </Table>
      <Table grid>
        <TableRow header>
          <TableHeader colSpan={2}>
            AATA<sup>5</sup>
          </TableHeader>
        </TableRow>
        <TableRow>
          <TableCell>
            <ul className="list-disc ml-4 text-sm p-1">
              <li>
                Ultrasound and AFP<sup>d</sup>
              </li>
              <li>Every 6 months</li>
            </ul>
          </TableCell>
        </TableRow>
      </Table>
    </>
  );
};
export default UltrasoundSurveilanceTablesMobile;
