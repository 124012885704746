import React, { FunctionComponent } from "react";
import MaybeClassProps from "../../../components/MaybeClassProps.type";
import Table, {
  TableRow,
  TableCell,
  TableHeader,
} from "../../../components/Table/Table";
import Strong from "../../../components/Strong";
import { mergeClassNames } from "../../../helpers";

const HCCSurveillanceTablesDesktop: FunctionComponent<MaybeClassProps> = ({
  className,
}) => {
  return (
    <>
      <div className="flex mb-1 ">
        <div className="md:w-1/2 mr-8">
          <Strong className="text-black-dark">At-risk populations</Strong>
          <Table grid className={mergeClassNames(className, "table-fixed")}>
            <TableRow>
              <TableHeader className="bg-red text-white w-20 align-top">
                <div className="pt-2">
                  AASLD<sup>1</sup>
                </div>
              </TableHeader>
              <TableCell>
                <ol className="list-decimal ml-4 text-sm p-1">
                  <li>All HBsAg+ persons with cirrhosis</li>
                  <li>People with a family history of HCC</li>
                  <li>People with hepatitis D virus infection</li>
                  <li>HBsAg+ Asian or African American men >40 years of age</li>
                  <li>HBsAg+ Asian women >50 years of age</li>
                </ol>
              </TableCell>
            </TableRow>
          </Table>
        </div>

        <div className="md:w-1/2 pb-6">
          <Strong className="text-black-dark">
            Recommended HCC surveillance
          </Strong>
          <Table
            grid
            className={mergeClassNames(className, "table-fixed h-full")}
          >
            <TableRow>
              <TableHeader className="bg-red text-white w-20 align-top">
                <div className="pt-2">
                  AASLD<sup>1</sup>
                </div>
              </TableHeader>
              <TableCell>
                <ul className="list-disc ml-4 text-sm p-1">
                  <li>Ultrasound ± AFP</li>
                  <li>Every 6 months</li>
                </ul>
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </div>

      <div className="flex">
        <div className="md:w-1/2 mr-8">
          <Table grid className={mergeClassNames(className, "table-fixed")}>
            <TableRow>
              <TableHeader className="bg-red text-white w-20 align-top">
                <div className="pt-2">
                  AATA<sup>5</sup>
                </div>
              </TableHeader>
              <TableCell>
                (Asian Americans)<sup>d</sup>
                <ol className="list-decimal ml-4 text-sm p-1">
                  <li>All HBsAg+ people with chronic hepatitis</li>
                  <li>People with cirrhosis or family history of HCC</li>
                </ol>
              </TableCell>
            </TableRow>
          </Table>
        </div>

        <div className="md:w-1/2">
          <Table
            grid
            className={mergeClassNames(className, "table-fixed h-full")}
          >
            <TableRow>
              <TableHeader className="bg-red text-white w-20 align-top">
                <div className="pt-2">
                  AATA<sup>5</sup>
                </div>
              </TableHeader>
              <TableCell>
                <ul className="list-disc ml-4 text-sm p-1">
                  <li>
                    Ultrasound and AFP<sup>d</sup>
                  </li>
                  <li>Every 6 months</li>
                </ul>
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </div>
    </>
  );
};
export default HCCSurveillanceTablesDesktop;
