import React from "react";

interface Props {
  children: string;
}

const NoBreak: React.FunctionComponent<Props> = ({ children }) => {
  return <span style={{ whiteSpace: "nowrap" }}>{children}</span>;
};

export default NoBreak;
