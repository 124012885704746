import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import HeroImage from "../components/HeroImage/HeroImage";
import Slab from "../components/Slab";
import Subheading from "../components/Subheading/Subheading";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import heroBox from "../images/hcc-awareness/hero-box.svg";
import heroBoxWithoutAnimation from "../images/hcc-awareness/hero-box-without-animation.svg";
import Footnotes from "../components/Footnotes";
import Card from "../components/Card/Card";
import Link from "../components/Link/Link";
import HCCSurveillanceTables from "../partials/Tables/HCCSurveillanceTables";
import ImageWrapper from "../components/ImageWrapper";

import Button from "../components/Buttons/ButtonPrimary";

import Strong from "../components/Strong";
import DonutGraph from "../components/DonutGraph/DonutGraph";
import Paragraph from "../components/Paragraph";

import References from "../components/References/References";
import ReferenceItem from "../components/References/ReferenceItem";

import AbbrList from "../components/Abbr/AbbrList";
import Abbr from "../components/Abbr/Abbr";
import tracker from "../tracker";

import NoBreak from "../components/NoBreak";
import JumpLink from "../components/Link/JumpLink";
import Parallax from "../components/Parallax/Parallax";

const PreventScreen = () => {
  const data = useStaticQuery(graphql`
    query {
      pillTumorDetection: file(
        relativePath: { eq: "hcc-awareness/pill-tumor-detection.png" }
      ) {
        ...TextQuery
      }
      infoIcon: file(relativePath: { eq: "hcc-awareness/info-icon.png" }) {
        ...TextQuery
      }
      slabPeople: file(
        relativePath: { eq: "hepatitis-b-management/5-0-slab-people.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 467) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pillSurvival: file(
        relativePath: { eq: "hcc-awareness/pill-survival.png" }
      ) {
        ...TextQuery
      }
      heroImage: file(relativePath: { eq: "hcc-awareness/hero-img.jpg" }) {
        ...HeroQuery
      }
      guidelines: file(
        relativePath: {
          eq: "hcc-awareness/chronic-hepatitis-B-screening-and-management-guidelines-1@2x.png"
        }
      ) {
        ...TextQuery
      }
      chbScreeningGuidelines: file(
        relativePath: {
          eq: "hepatitis-b-resources/chronic-hepatitis-b-screening-brochure.pdf"
        }
      ) {
        publicURL
      }
      viewCdcRecommendations: trackingJson(
        id: { eq: "viewCdcRecommendations" }
      ) {
        ...TrackingQuery
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Chronic Hepatitis B Information for Healthcare Professionals"
        description="Help your chronic hepatitis B patients reduce their risk of hepatocellular carcinoma (HCC)."
      />
      <HeroImage
        headLine={
          <span>
            Help your <br className="hidden md:block" />
            chronic <NoBreak>hepatitis B</NoBreak>{" "}
            <br className="hidden md:block" />
            patients reduce <br className="hidden md:block" />
            their risk of HCC.
          </span>
        }
        footer={
          <div className="text-xs md:text-white pb-4 md:pb-10 lg:pb-4">
            <Abbr term="HCC " title=" hepatocellular carcinoma." />
          </div>
        }
        fluidQuery={data.heroImage.childImageSharp.fluid}
        boxSrc={heroBox}
        boxSrcWithoutAnimation={heroBoxWithoutAnimation}
        imagePosition={{ desktop: { x: 60, y: 0 }, mobile: { x: 65, y: 0 } }}
      />
      <div className="md:-mt-16">
        <div className="container relative">
          <div className="flex flex-col md:flex-row -mx-4 sm:mt-4 md:mt-8">
            <Card className="md:w-1/2 mx-4 bg-grey py-6">
              <Strong
                color="black-dark"
                size="lg"
                className="pt-2 block tracking-tight"
              >
                Monitor patients for ALT and HBV DNA
              </Strong>
              <div className="text-black pt-2 pb-6">
                Patients with chronic hepatitis B need ongoing and consistent
                monitoring<sup>1</sup>
              </div>
              <JumpLink
                hideArrow
                tracking={tracker(data.viewCdcRecommendations)}
                to="#anchor-suboptimal-care"
              >
                Find out why
              </JumpLink>
            </Card>
            <Card className="md:w-1/2 mx-4 bg-grey py-6">
              <Strong color="black-dark" size="lg" className="pt-2 block">
                Conduct regular HCC surveillance
              </Strong>
              <div className="text-black pt-2 pb-2">
                Suboptimal surveillance puts chronic hepatitis B patients at
                risk of disease progression, including HCC and its sequelae
                <sup>2-4</sup>
              </div>
              <JumpLink
                hideArrow
                tracking={tracker(data.viewCdcRecommendations)}
                to="#anchor-whos-at-risk"
              >
                Review the guidelines
              </JumpLink>
            </Card>
          </div>
          <AbbrList>
            <Abbr term="ALT" title="alanine aminotransferase" />
            <Abbr
              term="HBV DNA"
              title="hepatitis B virus deoxyribonucleic acid."
            />
            <Abbr term="HCC" title="hepatocellular carcinoma" />
          </AbbrList>
        </div>
      </div>
      <Slab>
        <div className="h-full lg:py-4">
          <div className="AssetCard bg-white flex">
            <div className="AssetCard__Icon mr-2 md:mr-6 pt-2">
              <Img
                className="w-24 mr-2 flex-shrink-0 flex-grow-0"
                fluid={data.guidelines.childImageSharp.fluid}
                alt="Chronic hepatitis B screening and management guidelines PDF"
              />
            </div>
            <div className="flex flex-col w-full">
              <Strong color="black" size="lg">
                Chronic Hepatitis B Screening &amp; Management Guidelines
              </Strong>
              <Paragraph>
                Get an overview of disease management guidelines for chronic
                hepatitis B, including recommendations for HCC surveillance.
              </Paragraph>
              <Link
                download
                to={data.chbScreeningGuidelines.publicURL}
                className="transition-bg bg-green hover:bg-green-dark shadow-button mt-10 px-24 py-4 hidden md:inline-block text-center w-100"
                tracking={tracker(data.viewCdcRecommendations)}
              >
                <Strong color="white">Download the Guidelines</Strong>
              </Link>
            </div>
          </div>
        </div>
        <Link
          download
          to={data.chbScreeningGuidelines.publicURL}
          className="transition-bg bg-green  hover:bg-green-dark block shadow-button mt-6 w-full py-4 md:hidden text-center"
          tracking={tracker(data.viewCdcRecommendations)}
        >
          <Strong color="white">Download the Guidelines</Strong>
        </Link>
      </Slab>
      <Slab name="anchor-suboptimal-care">
        <Subheading className="tracking-tight w-full">
          Many people with chronic hepatitis B receive suboptimal care
        </Subheading>
        <Paragraph className="pb-8">
          Chronic hepatitis B patients were often not adequately monitored for
          ALT and HBV DNA, leaving them at risk for cirrhosis and HCC.
          <sup>2-4</sup>
        </Paragraph>
        <Card className="bg-grey">
          <div className="flex flex-col md:flex-row px-4 py-4">
            <div className="w-full md:w-1/4">
              <Strong
                color="black-dark"
                size="lg"
                className="block text-center md:text-left md:tracking-tight"
              >
                Percentage of chronic hepatitis B patients who were either never
                or not routinely monitored for the following important tests
                <sup>2,a</sup>:
              </Strong>
            </div>
            <div className="w-full md:w-1/4 mt-8 md:mt-0">
              <div className="w-full flex flex-col justify-between text-center h-full pb-8 md:pb-0 border-b md:border-b-0 md:border-r border-grey-light">
                <Strong color="red" className="text-lg">
                  ALT
                </Strong>
                <DonutGraph
                  className="px-8 lg:px-12 -mb-4 md:mb-0 mt-2"
                  percent={0.22}
                  alt="Donut graph showing 22%"
                  maxWidth={225}
                />
              </div>
            </div>
            <div className="w-full md:w-1/4 ">
              <div className="w-full flex flex-col justify-between text-center h-full pt-4 pb-8 md:pb-0 md:pt-0 border-b md:border-b-0 md:border-r border-grey-light">
                <Strong color="red" className="text-lg">
                  HBV DNA
                </Strong>
                <DonutGraph
                  className="px-8 lg:px-12 -mb-4 md:mb-0 mt-2"
                  percent={0.62}
                  alt="Donut graph showing 62%"
                  maxWidth={225}
                />
              </div>
            </div>
            <div className="w-full md:w-1/4">
              <div className="w-full flex flex-col justify-between text-center h-full pt-4 md:pt-0">
                <Strong color="red" className="text-lg px-4">
                  Hepatic Imaging in patients with cirrhosis
                </Strong>
                <DonutGraph
                  className="px-8 lg:px-12 -mb-4 md:mb-0 mt-2"
                  percent={0.86}
                  alt="Donut graph showing 86%"
                  maxWidth={225}
                />
              </div>
            </div>
          </div>
        </Card>
        <Footnotes className="mt-2">
          <sup>a</sup>This was a multicenter observational study of the Chronic
          Hepatitis Cohort Study (CHeCS), which included 2338 patients in the US
          with confirmed chronic hepatitis B.<sup>1</sup>
        </Footnotes>
      </Slab>
      <Slab name="monitoring">
        <Subheading className="w-full">
          Most chronic hepatitis B patients do not undergo regular
          hepatocellular carcinoma surveillance
        </Subheading>
        <Paragraph className="pb-8">
          HCC surveillance was suboptimal among chronic hepatitis B patients.
        </Paragraph>

        <Card className="bg-grey">
          <div className="flex flex-wrap items-end md:items-start px-2 md:px-4 py-2 md:py-4">
            <div className="w-full md:w-1/2">
              <Strong
                color="black-dark"
                size="lg"
                className="block text-center md:text-left md: pr-4"
              >
                Percentage of chronic hepatitis B patients who never underwent
                or received suboptimal HCC surveillance<sup>3,b,c</sup>:
              </Strong>
            </div>
            <div className="w-1/2 md:w-1/4">
              <div className="w-full flex flex-col justify-between text-center h-full md:pb-0">
                <DonutGraph
                  className="px-2 lg:px-12 md:px-8 mt-4 -mb-4 md:mb-0"
                  percent={0.78}
                  alt="Donut graph showing 78%"
                  maxWidth={225}
                />
                <Paragraph className="pb-2 uppercase">
                  Patients
                  <br />
                  without
                  <br />
                  Cirrhosis
                </Paragraph>
              </div>
            </div>
            <div className="w-1/2 md:w-1/4">
              <div className="w-full flex flex-col justify-between text-center h-full pt-4  md:pb-0 md:pt-0">
                <DonutGraph
                  className="px-2 lg:px-12 md:px-8 mt-4 -mb-4 md:mb-0"
                  percent={0.62}
                  alt="Donut graph showing 62%"
                  maxWidth={225}
                />
                <Paragraph className="pb-2 uppercase">
                  Patients
                  <br />
                  with
                  <br />
                  Cirrhosis
                </Paragraph>
              </div>
            </div>
          </div>
        </Card>
        <Footnotes className="mt-2">
          <sup>b</sup>Suboptimal HCC surveillance fails to meet the AASLD
          guideline for HCC, which recommends surveillance of persons at high
          risk of HCC with ultrasound every 6 months.
          <br />
          <sup>c</sup>This was a retrospective analysis of 1329 chronic
          hepatitis B patients from the San Francisco Bay Area (January
          1996-July 2013).<sup>3</sup>
        </Footnotes>
        <Button
          to="https://hepbmd.com/hepatitis-b-treatment"
          className="mt-8 text-center"
          tracking={tracker(data.viewCdcRecommendations)}
        >
          Explore Treatment Guidelines for Chronic Hepatitis B Patients
        </Button>
      </Slab>
      <Slab name="anchor-whos-at-risk">
        <Subheading>Who’s at risk?</Subheading>
        <Paragraph className="text-small text-black">
          <Strong>According to AASLD and AATA guidelines,</Strong> certain
          populations are at higher risk for HCC, based on individual history of
          cirrhosis, family history of HCC, age and other factors.
        </Paragraph>

        <Paragraph className="text-small text-black mb-12">
          <Strong className="text-black-dark">For at-risk patients,</Strong>{" "}
          ultrasound ± AFP are recommended for HCC surveillance every 6 months.
          <sup>1,5</sup>
        </Paragraph>

        <HCCSurveillanceTables />

        <AbbrList className="mt-4">
          <Abbr
            term="AASLD"
            title="American Association for the Study of Liver Diseases"
          />
          <Abbr term="AATA" title="Asian American Treatment Algorithm" />
          <Abbr term="AFP" title="alpha-fetoprotein" />
          <Abbr term=" HBsAg" title="hepatitis B surface antigen." />
        </AbbrList>

        <Footnotes className="mt-2">
          <sup>d</sup>Considered at high risk. Low- to moderate-risk patients
          such as low replication and immune tolerant should receive
          surveillance every 6-12 months. Patients who clear HBsAg may still
          develop HCC, and should continue surveillance.<sup>5</sup> <br />
          <sup>e</sup>AFP and CT scan or MRI at 3 months for people with
          elevated AFP or undergoing HCC treatment.
        </Footnotes>

        <Button
          to="https://hepbmd.com/hepatitis-b-screening-diagnosis"
          className="mt-6 text-center"
          tracking={tracker(data.viewCdcRecommendations)}
        >
          Learn About Implementing HBV Infection Screening Protocols
        </Button>

        <AbbrList className="mt-2">
          <Abbr term="HBV" title="hepatitis B virus." />
        </AbbrList>
      </Slab>
      <Slab>
        <Subheading className="tracking-tight w-full">
          Demonstrated improvements with routine HCC surveillance<sup>6</sup>
        </Subheading>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 md:pr-10 lg:pr-24">
            <ImageWrapper maxWidth={500}>
              <Img
                fluid={data.pillTumorDetection.childImageSharp.fluid}
                alt="About two times improvement in early tumor detection"
                className="my-6 mt-8"
              />
              <Img
                fluid={data.pillSurvival.childImageSharp.fluid}
                alt="About two times improvement in three year survival"
                className="my-6"
              />
            </ImageWrapper>
          </div>
          <div className="z-0 w-full self-end md:w-1/2 md:pl-12 lg:pl-24 pr-4 -mb-8">
            <Parallax speed={-1} percentage={0.2}>
              <ImageWrapper maxWidth={500}>
                <Img fluid={data.slabPeople.childImageSharp.fluid} />
              </ImageWrapper>
            </Parallax>
          </div>
        </div>
      </Slab>
      <Slab className="bg-green px-4 relative">
        <ImageWrapper left maxWidth={44} className="mb-4">
          <Img fluid={data.infoIcon.childImageSharp.fluid} />
        </ImageWrapper>
        <h3
          style={{ fontSize: "1.35rem" }}
          className="text-white font-bold antialiased mb-2"
        >
          HCC surveillance is recommended for many CHB patients, especially
          those with cirrhosis.<sup>1,5</sup>
        </h3>
      </Slab>
      <Slab>
        <div className="mx-auto w-full md:w-3/4 shadow p-6 z-10 mt-6">
          <div className="bg-white flex">
            <div className="mr-4">
              <Img
                className="w-16 mr-2 flex-shrink-0 flex-grow-0"
                fluid={data.guidelines.childImageSharp.fluid}
                alt="Chronic hepatitis B screening and management guidelines PDF"
              />
            </div>
            <div className="flex flex-col w-2/3">
              <Strong
                color="black-dark"
                size="md"
                className="block flex-initial"
              >
                Chronic Hepatitis B Screening & Management Guidelines
              </Strong>

              <Paragraph className="text-xs">
                Get an overview of disease management guidelines for chronic
                hepatitis B, including recommendations for HCC surveillance.
              </Paragraph>
              <Strong>
                <Link
                  to={data.chbScreeningGuidelines.publicURL}
                  className="underline text-green inline-block mt-2 py-4 w-100 text-sm"
                  tracking={tracker(data.viewCdcRecommendations)}
                >
                  <span className="">Download the Guidelines</span>
                </Link>
              </Strong>
            </div>
          </div>
        </div>
        <References className="mb-32 mt-10">
          <ReferenceItem>
            . Terrault N, Lok A, McMahon B, et al. Update on prevention,
            diagnosis, and treatment of chronic hepatitis B: AASLD 2018
            hepatitis B guidance. <em>Hepatology</em>. 2018;67(4):1560-1599.
          </ReferenceItem>
          <ReferenceItem>
            Spradling P, Xing J, Rupp, L et al. Infrequent clinical assessment
            of chronic hepatitis B patients in United States general healthcare
            settings. <em>Clin Infect Dis</em>. 2016;63:1205-1208.
          </ReferenceItem>
          <ReferenceItem>
            Wang C, Chen V, Vu V, et al. Poor adherence and low persistency
            rates for hepatocellular carcinoma surveillance in patients with
            chronic hepatitis B. Medicine (Baltimore). 2016;95:e4744.
          </ReferenceItem>
          <ReferenceItem>
            Centers for Disease Control and Prevention.{" "}
            <em>MMWR Morb Mortal Wkly Rep</em>. 2008;57:1-20.
          </ReferenceItem>
          <ReferenceItem>
            Tong M, Pan C, Han S, et al. An expert consensus for the management
            of chronic hepatitis B in Asian Americans.{" "}
            <em>Aliment Pharmacol Ther</em>. 2018;47(8):1181-1200.
          </ReferenceItem>
          <ReferenceItem>
            Singal A, Pillai A, Tiro J. Early detection, curative treatment, and
            survival rates for hepatocellular carcinoma surveillance in patients
            with cirrhosis: a meta-analysis. <em>PLOS Med</em>.
            2014;11:e1001624.
          </ReferenceItem>
        </References>
      </Slab>
    </Layout>
  );
};
export default PreventScreen;
